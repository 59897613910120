import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
import animations from './parts/animations';

$(window).load(function () {
    $('#page-wrap').fadeIn();

});

jQuery(document).ready(function ($) {
	if($('.home').length) {
		animations().parallax();
		if(window.innerWidth > 768){
		animations().movePanels();
		}
		animations().backgroundChange();
		animations().fadeIn();
		animations().bannerVideo();
	}

$(window).scroll(function () {
    $('.off-screen').each(function (index) {
        if ($(this).isOnScreen()) { 
            $(this).addClass('is-inview');
        }
    });
	// ScrollTrigger.refresh() 
});
$('.off-screen--static').each(function (index) {
    $(this).addClass('is-inview');
});
$(document).on('click', '.anchor, .skip-link', function (event) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});


	buttonAnim: {
		$( ".button-anim-wrap > a" ).mouseenter(function(e) {
			var parentOffset = $(this).offset(); 
		   
			var relX = e.pageX - parentOffset.left;
			var relY = e.pageY - parentOffset.top;
			$(this).prev(".button-anim-wrap__circle").css({"left": relX, "top": relY });
			$(this).prev(".button-anim-wrap__circle").removeClass("desplode-circle");
			$(this).prev(".button-anim-wrap__circle").addClass("explode-circle");
		 
		 });
		 
		 $( ".button-anim-wrap > a" ).mouseleave(function(e) {
		 
			  var parentOffset = $(this).offset(); 
		 
			  var relX = e.pageX - parentOffset.left;
			  var relY = e.pageY - parentOffset.top;
			  $(this).prev(".button-anim-wrap__circle").css({"left": relX, "top": relY });
			  $(this).prev(".button-anim-wrap__circle").removeClass("explode-circle");
			  $(this).prev(".button-anim-wrap__circle").addClass("desplode-circle");
		 
		 });
	}
	headerAnim: {
		$( ".menu__wrap > .hamburger-wrap" ).mouseenter(function(e) {
			var parentOffset = $(this).offset(); 
		   
			var relX = e.pageX - parentOffset.left;
			var relY = e.pageY - parentOffset.top;
			$(this).prev(".menu__wrap__circle").css({"left": relX, "top": relY });
			$(this).prev(".menu__wrap__circle").removeClass("desplode-circle");
			$(this).prev(".menu__wrap__circle").addClass("explode-circle");
		 
		 });
		 
		 $( ".menu__wrap > .hamburger-wrap" ).mouseleave(function(e) {
		 
			  var parentOffset = $(this).offset(); 
		 
			  var relX = e.pageX - parentOffset.left;
			  var relY = e.pageY - parentOffset.top;
			  $(this).prev(".menu__wrap__circle").css({"left": relX, "top": relY });
			  $(this).prev(".menu__wrap__circle").removeClass("explode-circle");
			  $(this).prev(".menu__wrap__circle").addClass("desplode-circle");
		 
		 });
	}
	slider: {
		$('.article-slider__details').slick({
			dots: true
			, arrows: false
			, fade: true
			, autoplay: true
			, autoplaySpeed: 4500
			, asNavFor: '.article-slider__images'
		, });
		$('.js-gallery-slider').slick({
			dots: false
			, arrows: false
			, fade: true
			, autoplay: true
			, autoplaySpeed: 4500
			, asNavFor: '.js-gallery-nav'
		, });
		$('.js-gallery-nav').slick({
			dots: false
			, arrows: true
			, fade: false
			, slidesToShow: 4  
			, slidesToScroll: 1
			, autoplay: true
			, autoplaySpeed: 4500
			, asNavFor: '.js-gallery-slider'
			, nextArrow: '.slide-controls__next'
			,prevArrow: '.slide-controls__prev'
		, });
		$('.js-stat-slider').slick({
			dots: true
			, arrows: false
			, fade: true
			, autoplay: true
			, autoplaySpeed: 4500
		, });
		$('.js-quote-slider').slick({
			dots: false
			, arrows: false
			, fade: false
			, autoplay: true
			, autoplaySpeed: 4500
			, slidesToShow: 2
			,slidesToScroll: 1
			, responsive: [
				{
				   breakpoint: 899,
				   settings: {
					slidesToShow: 1
				   }
				 
				},
			]
		, });
		$('.article-slider__images').slick({
			dots: false
			, arrows: false
			, autoplay: true
			, autoplaySpeed: 4500
			, asNavFor: '.article-slider__details'
		, });
		$('.js-past-events-slider').slick({
			dots: true
			, arrows: false
			, autoplay: true
			, autoplaySpeed: 4500
			, appendDots: $('.past-event-dots')
		, });
		$('.footer__logos').slick({
			dots: true
			, arrows: false
			, fade: false
			, autoplay: true
			, autoplaySpeed: 4500
			, mobileFirst: true
			, slidesToShow: 1
			, slidesToScroll: 1
			, responsive: [
			   {
				  breakpoint: 899,
				  settings: "unslick"
			   },{
				breakpoint: 600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			
		 },
		   
			]
		, });
		
	}
	anchorlinks: {
	
	}
	fixedheader: {
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 150) {
					$('.header').addClass('fixed-header');
				}
				if ($(window).scrollTop() >= 160) {
					$('.header').addClass("transform-none");
				}
				else {
					$('.header').removeClass('fixed-header');
					$('.header').removeClass("transform-none");
				}
			});
		});
	}
	mobile: {
		///MOBILE MENU - FOR USE WITH TWK_NAV_WALKER
		//                $('.menu .menu-item-has-children > a').click(function (event) {
		//                    event.preventDefault();
		//                    $(this).parent().children('li .sub-menu-wrap').addClass('transform-none');
		//                });
		//                $('.back-menu').click(function () {
		//                    $(this).parent().parent().removeClass('transform-none');
		//                });
	}

	dynamicVideo: {
		if($('.dynamic-video').length) {			

			gsap.from($('.dynamic-video__cta'), {
				x: '100%', 				
				scrollTrigger: {
					trigger: "body",
					start: "top -5%",
					end: "top -55%",					
					// markers: true,					
					toggleActions: "play reverse play reverse"
				},
				duration: 0.4
			});
			gsap.to($('.dynamic-video__cta span'), {
				opacity: 1, 				
				scrollTrigger: {
					trigger: "body",
					start: "top -5%",
					end: "top -55%",					
					toggleActions: "play reverse play reverse" 
				},
				duration: 0.2
			});
		}
	}

	magnific: {
		///IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe'
			, mainClass: 'mfp-fade'
			, removalDelay: 160
			, preloader: false
			, fixedContentPos: false
		});
		$('.magnific-inline').magnificPopup({
			type: 'inline'
			, mainClass: 'mfp-fade'
			, removalDelay: 160
			, preloader: false
			, fixedContentPos: false
		});
        // GALLERY IMAGE
  
		$('.gallery').each(function() { // the containers for all your galleries
			$(this).magnificPopup({
				delegate: 'a', // the selector for gallery item
				type: 'image',
				
				
				image: {
					verticalFit: true,
					titleSrc: function(item) {
						return item.el.attr('title');
					}
				},
				gallery:{
					enabled:true
				}
			});
		});
	}
	responsiveIfrmae : {
		if (document.querySelectorAll('.article iframe[src*=youtube]').length) {
			const iframes = document.querySelectorAll('.article iframe');
			
				var wrap = function (toWrap, wrapper) {
				  wrapper = wrapper || document.createElement('div');
				  wrapper.classList.add('embed-responsive');
				  wrapper.classList.add('embed-responsive-16by9');
				  toWrap.parentNode.appendChild(wrapper);
				  return wrapper.appendChild(toWrap);
			  };
			  [...iframes].forEach((iframe) => {
			  wrap(iframe);
			
			  });
			}
	}
	externallinks: {
		$('.footer a, .header a').each(function () {
			var a = new RegExp('/' + window.location.host + '/');
			if (!a.test(this.href)) {
				$(this).click(function (event) {
					event.preventDefault();
					window.open(this.href, '_blank');
				});
			}
		});
		pdfs: {
			///OPEN PDFS IN NEW WINDOW
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}
	links: {
		$(document).on('click', '.anchor', function (event) {
			event.preventDefault();
			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		});
	}
	search: {
		// $('.search-icon').on('click', function (e) {
		// 	$('.searchform').toggleClass('search-open');
		// 	$(this).toggleClass('submit-zindex');
		// 	$('.select-lang').removeClass('transform-height');
		// 	$('#menu-main-menu-top, .lang_dropdown').fadeOut();
		// 	$("#s").focus();
		// 	setTimeout(function () {
		// 		$('.close-search').fadeIn();
		// 	}, 300);
		// });
		// $('.close-search').on('click', function (e) {
		// 	$(this).fadeOut();
		// 	$('.searchform').removeClass('search-open');
		// 	$('.search-icon').removeClass('submit-zindex');
		// 	$('#menu-main-menu-top, .lang_dropdown').fadeIn();
		// });
	}

	utils: {
		function hasClass(el, className) {
            return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
        }
        
        function addClass(el, className) {
            if (el.classList) {
                el.classList.add(className);
            } else if (!hasClass(el, className)) {
                el.className += ' ' + className;
            }
        }
        
	}
	onLoad: {
		$(document).ready(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
    }
    menu: {
        const pageWrap = document.querySelector('#content-wrap');
        const burger = document.querySelector('.hamburger');
        const menu = document.querySelector('.header__menu__main');
		const closeMenu = document.querySelector('.header__menu__main__close');
		const closeButtons = document.querySelectorAll('.back-button');
        //const menuAnimate = document.querySelector('.header__menu__main .off-screen');
        const menuItems = document.querySelectorAll('#menu-main-menu > li.menu-item-has-children');
        const subMenus = document.querySelectorAll('.header__menu__sub');

        //animate burger
        function toggleOpen(element) {
            element.classList.toggle('open');
            //element.classList.toggle('off-screen--static');
        }

        function dullBackground(element) {
            element.classList.toggle('menu-open');
            //element.classList.toggle('off-screen--static');
        }

        //animate burger and menu on click on hamburger
        burger.onclick = function(){
            toggleOpen(burger);
            //toggleOpen(menuAnimate);
            toggleOpen(menu);
			dullBackground(pageWrap);
		};

		pageWrap.onclick = function(){
			// if(hasClass(pageWrap, 'menu-open')) {
			// 	toggleOpen(burger);
			// 	//toggleOpen(menuAnimate);
			// 	toggleOpen(menu);
			// 	dullBackground(pageWrap);
			// }
		};
		

        closeMenu.onclick = function(){
            toggleOpen(burger);
            //toggleOpen(menuAnimate);
            toggleOpen(menu);
			dullBackground(pageWrap);
			menuItems.forEach(function(menuItem) {
				menuItem.classList.remove('active');
			});
            subMenus.forEach(function(subMenu) {
                subMenu.classList.remove('open');
            });
		};
		
		closeButtons.forEach(function(button) {
			button.onclick = function(){
				subMenus.forEach(function(subMenu) {
					subMenu.classList.remove('open');
				});
				menuItems.forEach(function(menuItem) {
					menuItem.classList.remove('active');
				});
			}
		});

        menuItems.forEach(function(item) {
            item.children[0].onclick = function(e){
                e.preventDefault();
                menuItems.forEach(function(menuItem) {
                    menuItem.classList.remove('active');

                });
                item.classList.add('active');
                subMenus.forEach(function(subMenu) {
                    subMenu.classList.remove('open');
                    //subMenu.classList.add('off-screen--static');
                });

                let submenu = item.childNodes;
                toggleOpen(submenu[2]); 
            };
		});
		
		$('#content-wrap').on('click', function() {
			burger.classList.remove('open');
            menu.classList.remove('open');
			pageWrap.classList.remove('menu-open'); 
			subMenus.forEach(function(subMenu) {
                subMenu.classList.remove('open');
            });
		});
    }
    img: {
        
        let imgContainers;
        let len;
        
        if (!Modernizr.objectfit) {
            imgContainers = document.querySelectorAll('.image-wrap');
            len = imgContainers.length;
        
            for (var i=0; i<len; i++) {
                const container = imgContainers[i],
                    imgUrl = container.querySelector('img').getAttribute('src');

                if (imgUrl) {
                    container.style.backgroundImage = 'url(' + imgUrl + ')';
                    addClass(container, 'image-wrap--ie');  
                }
            }
        }
    }

	
   
	modal: {
		let modalAnchors = document.querySelectorAll('.modal-anchor');
		let modalClose = document.querySelectorAll('.modal__close');
		let modals =  document.querySelectorAll('.modal');

		//open the modals
		modalAnchors.forEach(function(anchor) {
            anchor.onclick = function(e){
				let modalID = anchor.getAttribute("modal-id");
				let modalChild = document.querySelector('#'+modalID);
				modalChild.classList.toggle('modal--open');
				$(modalChild).fadeIn();
            };
		});
		
		//close the modals
		
		modalClose.forEach(function(close) {
            close.onclick = function(e){
				modals.forEach(function(modal) {
					modal.classList.remove('modal--open');
					$(modal).fadeOut();
				});
            };
		});
		  
	}
	ajaxpage: {
		$('.ajax-anchor').on('click', function() {
			let url = $(this).attr('ajax-url');
			window.history.pushState( null , null, url );
			//window.history.popState( null , null, url );
			var pageID = $(this).attr('ajax-target');
			$('.ajax-anchor').removeClass('current_page_item');
			$(this).addClass('current_page_item');
            indexAjax(pageID);
		});
	}
	accordion: { 
		function toggleAccordion() {
	
			$('.js-accordion-target').not($(this).next('.js-accordion-target')).slideUp();
			$('.js-accordion-plus').not($(this).find('.js-accordion-plus')).removeClass('active');
			$(this).next('.js-accordion-target').slideToggle();
			$(this).find('.js-accordion-plus').toggleClass('active'); 
			// setTimeout(function () {
			// 	var resizeEvent = window.document.createEvent('UIEvents'); 
			// 	resizeEvent.initUIEvent('resize', true, false, window, 0); 
			// 	window.dispatchEvent(resizeEvent);
			// }, 500);
		}
		
		const accordions = document.querySelectorAll(".js-accordion-trigger");
		accordions.forEach(function(accordion) {
			accordion.addEventListener("click", toggleAccordion, false);
		});
	}
	scroll: {
	
		// $('.ajax-loader__button').on('click', function () {
		// 	scroll.update();
		// });
	}
	index: {
		// $('.sf-field-taxonomy-school li').on('click', function() {
		// 	var resizeEvent = window.document.createEvent('UIEvents'); 
		// 	resizeEvent.initUIEvent('resize', true, false, window, 0); 
		// 	window.dispatchEvent(resizeEvent);
		// });
	}
	backbutton: {
	
		
	}
	counters: {
		
	}
	payment: {
	
			// jQuery('.gform_wrapper input[type=submit]', this).click(function() {
			// 	jQuery('.gform_wrapper input[type=submit]').css('display','none');
			// });
	
	}
	tables: {
		$('table').wrap('<div class="tables-responsive"></div>')
	}
	bannernotice: {
		setTimeout(function() {
			$('.banner-notice').fadeIn();
		}, 500);
		$('.banner-notice__close').on('click', function() {
			$('.banner-notice').fadeOut();
		});
	}
	
}); 


if(window.matchMedia("(min-width: 600px").matches) {
	$(window).scroll(function () {
		// console.log('r');
		ScrollTrigger.refresh() 
	});
}